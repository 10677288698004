import React from 'react'
const LeaderBoard = (props) => {
  console.log(props.leader)
  return (
   <>
  {/* leaderboard*/}
  <div id="test-swipe-1" className="col s12 ">
  <p className="text_detl">Leaderboard will be updated every 24hrs.</p>
  <div className="leaderboard_section">      
    <div className="ldr_list">
      <div className="ldr_itm">
        <div className="ldr_sb_itm">
          <img src="" alt="kbc_icon" />
          <p>Avdhesh</p>
        </div>
        Hello Jee
        <div className="ldr_sb_itm">
          <h2>#1</h2>
        </div>
      </div>
      <div className="ldr_itm">
        <p>
          <span>Total Points:</span> 553
        </p>
      </div>
    </div>
  </div>
  </div>
   </>
  )
}

export default LeaderBoard
