import React from 'react'
import share from '../../images/share.svg'
const Share = ({ url, title, text }) => {
    const handleShare = async () => {
        if (navigator.share) {
            try {
              await navigator.share({
                title: title || document.title,
                text: text || '',
                url: url || window.location.href,
              });
              console.log('Share was successful.');
            } catch (error) {
              console.error('Share failed:', error);
            }
          } else {
            // Fallback
            window.prompt('Copy this URL and share it:', url);
          }
      };







  return (
    <>
    <a>
        <img src={share} onClick={handleShare}></img>
    </a>
    </>
  )
}

export default Share
