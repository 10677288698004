import React from 'react'

const QuizFooter = () => {
  return (
   <>
  <div className="footernav1">
			 <p>Copyright @ mChamp Entertainment Pvt Ltd </p>
				    <p>2024 All rights reserved.</p>
             </div>
   </>
  )
}

export default QuizFooter
