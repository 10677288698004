import React from 'react'
import { BASE_URL} from '../AllApi/CommonUrl';
import Cookies from 'js-cookie';
import { Redeme } from '../AllApi/ReedemNow';
import { useNavigate } from "react-router-dom";
const user_id= typeof (Cookies.get('loginId'))==="undefined" ||Cookies.get('loginId') =="" ?Cookies.get('userId'):Cookies.get('loginId')
const tok=typeof (Cookies.get('logintoken'))==="undefined"|| Cookies.get('logintoken')==""?Cookies.get('idtoken'):Cookies.get('logintoken')
const WalletInfo = (props) => {
  const navigate = useNavigate()
  const res=async(quantity)=>{
      try {
        const endpoint='offer/redeemUserDiamondApi/'
        const result= await Redeme(props.user_id,quantity,BASE_URL,endpoint,props.tok);
         console.log('redeem',result);
         alert(result.result)
         window.location.reload();
         navigate("/Progress");
      }
      catch{}
    }
  return (
    <>
    <div className="coin_inf_p">
      <div className="coin_img_p_left">
        <img src={props.limage} alt="coin bg" />
      </div>
      <div className="wallet_pg">
        <p className="ttl_txt">{props.name}</p>
        <h2 className="ttl_balance">
          <img src={props.image} alt="coin" />
          { props.value}
        </h2>
        {/* {props.status==1 &&  <button className="btn_white btn_gren">Buy Now</button>}
        {props.status==2 &&    props.value>0 &&
         <button className="btn_white btn_gren" onClick={() => res(props.value)}>Redeem Now</button>}
         {props.status==2 &&    props.value<=0 &&
         <button className="btn_white btn_gren disable">Redeem Now</button>} */}
      </div>
    </div>
    </>
  )
}

export default WalletInfo
