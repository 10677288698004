import React from 'react'
import img from '../../images/menu_ic.svg'
import logo from '../../images/logo.png'
import wallet from '../../images/wallet_ic_h.png'
import  { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Share from '../User/Share'
import addhome from '../../images/add_to_home.svg'
const Navbar = () => {
  const navigate = useNavigate()
  const url = 'https://mte.gamzey.com/';
  const title = 'Trivia Earn Web App';
  const text = 'I found this amazing site and thought you might like it.';
  const user_id= Cookies.get('loginId')
  const username= Cookies.get('username')
  // alert(user_id)
  const logout=()=>{
    Cookies.set('is_login_check', false);  
      Cookies.set('loginId','');
      Cookies.set('logintoken',''); 
     console.log()
    // alert(Cookies.get('is_login_check'))
  }
  const scree=()=>{
    if ('serviceWorker' in navigator) {    
      console.log("Will the service worker register?");    
      navigator.serviceWorker.register('service-worker.js')
          .then(function(reg){        
        console.log("Yes, it did.");     
        }).catch(function(err) {        
        console.log("No it didn't. This happened:", err)    
        });
     }
  }
  return (
   <>
   <div className="header">
  <nav>
    <div className="h_left">
      {/* <a href="https://games.mchamp.xyz/"  className="sidenav-trigger" data-target="slide-out"  >
        <img src={img} alt="menu" className="hamburger"  onClick={() => setToggle(!toggle)} />
      </a> */}
    <NavLink to={"/"}>
        <img src={logo} alt="menu" className="logo" />
      </NavLink>
    </div>
    <div className="h_right">
      {/* <NavLink >
        <img src={diamond} alt="wallet" />
      </NavLink>
      <NavLink>
        <img src={ept} alt="wallet" />
      </NavLink> */}
      {/* <a>
         <img src={addhome} alt="wallet"  onClick={scree}/>
      </a> */}


      <NavLink to={"/Progress"} >
        <img src={wallet} alt="wallet" />
      </NavLink>
      

      
{user_id=="" ||typeof(user_id)=='undefined'? <NavLink to={'/Login'} className='l_btn'>
        <button className="btn_white btn_gren mrgn_0">Login</button>
      </NavLink> :<p className="">{username}</p>
       }
       <Share url={url} title={title} text={text}></Share>
      
      

    </div>
  </nav>
</div>
     
   </>
  )
}

export default Navbar
