import React from 'react'
import timer from '../../images/timer.png'
import dd from '../../images/diamond.png'
import { useState, useEffect } from 'react';
import Right from '../header/Right'
import { useParams } from 'react-router-dom'
import { gameData } from '../AllApi/GameApi';
import { Details } from '../AllApi/GameDetailsApi';
import WonBreak from './WonBreak';
import PreWinn from './PreWinn';
import LeadBoard from './LeadBoard';
import { walletData } from '../AllApi/WalletApi';
import { useNavigate } from "react-router-dom";
import { CoinData } from '../AllApi/CoinDeductionApi';
import { BASE_URL,AD_SHOW} from '../AllApi/CommonUrl';
import Navbar from '../header/Navbar';
import Cookies from 'js-cookie';
import QuizFooter from '../footer/QuizFooter';
import AdsComponents from'../Adsense/AdsComponents'
const GameDetails = () => {
  const [Value, setValue] = useState(0);
  const [arr, setArr] = useState([]);
  const [winn, setWinn] = useState([]);
  const [pre_winners, setPre_winners] = useState([]);
  const [lead, setLead] = useState([]);
  const [avlcoins, setAvlcoins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let {contest } = useParams();
  console.log('contest id',contest)
  const entry_fees = arr.contest_cost
  const navigate = useNavigate()
  const constid = arr.contest_id
   const user_id= typeof (Cookies.get('loginId'))==="undefined" ||Cookies.get('loginId') =="" ?Cookies.get('userId'):Cookies.get('loginId')
   const tok=typeof (Cookies.get('logintoken'))==="undefined"|| Cookies.get('logintoken')==""?Cookies.get('idtoken'):Cookies.get('logintoken')
   console.log('userID',user_id)
   console.log('Token',tok)
  const res = async () => {
    if (parseInt(avlcoins) >= parseInt(entry_fees)) {
      try {
        const endpoint = 'userheaderdetail/coinDeduct';
        const result = await CoinData(user_id, entry_fees, arr.contest_name, BASE_URL, endpoint, tok);
        navigate(`/GameLevel/${constid}`);
      }
      catch { }

    }
    else {
      alert(" You Don't Have Enough Coin ")
    }
  }
  useEffect(() => {
    ; (async () => {
      try {
        if(!user_id){
          navigate("/");
        }
        const gameEnd = 'goldgame/gameSingleData'
        const game = await gameData(contest,user_id, BASE_URL, gameEnd, tok);
        setArr(game.result.game_details[0])
        const gameendpoint = 'goldgame/gameDetail';
        const gamedetail = await Details(contest, user_id, BASE_URL, gameendpoint, tok);
        setWinn(gamedetail.winbreakup)
        setPre_winners(gamedetail.pre_winners)
        setLead(gamedetail.winners)
        const WalletEndPoint = 'userheaderdetail/homecategory'
        const result = await walletData(user_id, BASE_URL, WalletEndPoint, tok); // Replace with your endpoint
        setAvlcoins(result.data.wallet.coin_balance)
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  return (
    <>
      <div className="section">
        <div className="s_left">
         {/* <DetailsHeader name={arr.contest_name}path={'/'} status={arr.joinbuttontxt} avlcoins={avlcoins} fees={entry_fees}constid={constid} ></DetailsHeader> */}
         <Navbar></Navbar>
          {isLoading && <div className='center ldr_center'>
            <div className="preloader-wrapper big active">
              <div className="spinner-layer spinner-blue">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div><div className="gap-patch">
                  <div className="className"></div>
                </div><div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>

          }
          {/* {isLoading ===false &&
            // <div class="trnmnt_header" style={{ backgroundImage: `url(${arr.image_url} )` }}></div>
            <div class="center">
              <img src={arr.image_url} class="top_img_bane"/>
            </div>
          } */}
         
<div className="g_ads">
<p className="sponsored">Advertisement</p>

{AD_SHOW===1&&
  <AdsComponents adClient={"ca-pub-2489070569479477"} adSlot={1654381185} adFormat={"auto"} responsive={"true"}></AdsComponents>      }
      </div>
{isLoading ===false &&
  
          <div class="game_det">
            
            {/* <div class="flexx">
              <p class="l_ttl">{arr.contest_name}</p>
              {/* <button class="htp">How To Play</button>
            </div> */}
            <div class="flex_3 mrgn_100">
              <div className='flex_3'>
                <img src={timer} alt="timer" className='img_24'/>
                <div>
                  <p class="text_bg redd">{arr.time_end}</p>
                  <p className='txt_sm2'>End</p>
                </div>
              </div>
              
              <div className='flex_3'>
                <img src={dd} alt="timer" className='img_24'/>
                <div>
                  <p class="text_bg bluee">{arr.prize_pool}</p>
                  <p className='txt_sm2'>Prize Pool</p>
                </div>
              </div>
              {/* <div>
                <img src={user} alt="timer" />
                <p class="text_bg bluee">{arr.totalParticipant}</p>
                <p>User Playing</p>
              </div> */}
               {arr.joinbuttontxt=="Completed" &&<button class="btn_white btn_gren btn_wd disable" > {arr.joinbuttontxt}</button>
}
{arr.joinbuttontxt=="Play Now" &&<button class="btn_white btn_gren btn_wd" onClick={res}>{arr.joinbuttontxt}</button>}
            </div>
            <div class="tab_n mrgin_10_tb">
              <ul id="tabs-swipe-demo" className="tabs">
              <li className={`tab col s6  ${(Value !=1 && Value !=2)  ? 'tab_active' : ''}`}>
                  <a onClick={() => setValue(3)}>Winning Breakup</a>
                </li>
                <li className={`tab col s6  ${Value == 2 ? 'tab_active' : ''}`}>
                  <a onClick={() => setValue(2)}>Prev. Winners</a>
                </li>
                <li className={`tab col s6  ${Value == 1? 'tab_active' : ''}`}>
                  <a onClick={() => setValue(1)} className="active"  >
                    LeaderBoard
                  </a>
                </li>
              </ul>
            </div>
            {Value ==1&&
              <LeadBoard lead={lead}></LeadBoard>

            }
            {Value === 2 &&
              <PreWinn pre_winners={pre_winners}></PreWinn>
            }
            {(Value !==1 && Value !==2)&&
              <WonBreak won={winn}></WonBreak>
            }
           {/* added by aman mishra 9august09 */}
              <div className="btm_fix">

              {arr.joinbuttontxt=="Completed" &&<button class="btn_white btn_gren disable " > {arr.joinbuttontxt}</button>

                        }
                         {arr.joinbuttontxt=="Play Now" &&<button class="btn_white btn_gren" onClick={res}> Play Now</button>

                          }

              </div>

          </div>
          }
          <QuizFooter></QuizFooter>
        </div>
        <Right></Right>
      </div>
    </>
  )
}
export default GameDetails
