import React from 'react'
import CateHeader from '../header/CateHeader'
import debit from '../../images/debit.png'
import diamond from '../../images/diamond.png' 
import credit from '../../images/credit.png'
import Right from '../header/Right'
import coin from '../../images/coin.png'
import { useEffect, useState } from 'react'
import { History } from '../AllApi/History';
import { BASE_URL} from '../AllApi/CommonUrl';
import Cookies from 'js-cookie';
const RedeemHist = () => {
  const user_id= typeof (Cookies.get('loginId'))==="undefined"?Cookies.get('userId'):Cookies.get('loginId')
   const tok=typeof (Cookies.get('logintoken'))==="undefined"?Cookies.get('idtoken'):Cookies.get('logintoken')
  const[hist,sethist]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ; (async () => {
      try {
        const EndPoint='offer/redeemUserDiamondHistory'
        const resp = await History(user_id,BASE_URL,EndPoint,tok);
        sethist(resp.result)
        setIsLoading(false)
                } catch (error) {
        console.log(error)
      }
    })()
  }, []);
  return (
<>
      <div className="section">
      <div className="s_left">
      <CateHeader name={'Transection History'} path={'/Progress'}></CateHeader>
      {
       isLoading===false && hist.length==0 && <h6 style={{color:"red",textAlign:"center"}}>No history </h6>
      }
      {  isLoading===false&& hist!=null && 
  hist.map((key,index) => {
    return(
      <div class="ldr_list">
      <div class="ldr_itm">
        <div className='ldr_sb_itm'>
        {key.msg!=null && key.credit_status==1&&
            <img src={credit} alt='credit' />
            }
             {key.msg!=null && key.credit_status==2&&
            <img src={debit} alt='credit' />
            }
          <div>
          {key.msg!=null &&  key.credit_status==1&&
            <p className='ldr_sb_itm'>Earn Coin </p>
            }
            {key.msg!=null &&  key.credit_status==2&&
            <p className='ldr_sb_itm'>Deduct Coin </p>
            }
            <p className='tming'>{key.create_date}</p>
          </div>
        </div>
        <div class="ldr_sb_itm entry_value">
          <p class="etry_am">
            <img src={coin}alt="diamond"></img>
            {key.diamond_quantity}
          </p>
        </div>
        
      </div>
  </div>
    )
  })
} 
      
      </div>
    <Right></Right>
    </div>
    </>
      )
}

export default RedeemHist
