import React from 'react'
import GameHead from '../Game/GameHead'
import Navbar from '../header/Navbar'
import Right from '../header/Right'
import { BASE_URL,tok,AD_SHOW} from '../AllApi/CommonUrl';
import { gameData } from '../AllApi/GameApi';
import { useState, useEffect } from 'react'
import { checkAndHandleUserId} from '../AllApi/CommonUrl';
import QuizFooter from '../footer/QuizFooter';
import gameh from '../../images/tournamentHeader.png'
import AdsComponents from'../Adsense/AdsComponents'
const GameRoute = () => {
  let { id } = 214;
  const [arr, setArr] = useState([]);
  // const[avlcoins,setAvlcoins]=useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ; 
    (async () => {
	      checkAndHandleUserId();
      try {
             // const WalletEndPoint='userheaderdetail/homecategory'
        // const result = await walletData(user_id,BASE_URL,WalletEndPoint,tok); // Replace with your endpoint
        // setAvlcoins(result.data.wallet.coin_balance)
        const gameEnd='goldgame/gameContestList'
        const game = await gameData(id,0,BASE_URL,gameEnd,tok);
        setArr(game.result.game_details)
        setIsLoading(false);
            } catch (error) {
        console.log(error)
      }
      console.log(`Page rendered in ${performance.now().toFixed(2)} ms.`);
    })()
  }, []);
  console.log('useffets',arr)
     return (
         <div className="section">
         <div className="s_left">
          <Navbar></Navbar>
          {/* <div class="center">
            <img src={gameh} className='top_img_bane' alt=''></img>

          </div> */}
          <p className="sponsored">Advertisement</p>
          <div className="g_ads">
          
{AD_SHOW===1&&
      <AdsComponents adClient={"ca-pub-2489070569479477"} adSlot={6715605365} adFormat={"auto"} responsive={"true"}></AdsComponents>
      }
      </div>
          <div className="game_list">
          {/* <p class="l_ttl">All Games</p> */}
          {isLoading && <div className='center ldr_center'>
              <div className="preloader-wrapper big active">
                <div className="spinner-layer spinner-blue">
                  <div className="circle-clipper left">
                    <div className="circle"></div>
                  </div><div className="gap-patch">
                    <div className="className"></div>
                  </div><div className="circle-clipper right">
                    <div className="circle"></div>
                  </div>
                </div>
              </div>
            </div>}
         {isLoading==false &&
          <GameHead arr={arr}></GameHead>
         }

    </div>
    <QuizFooter></QuizFooter>
    </div>
    <Right></Right>

    </div>
  )
}

export default GameRoute
