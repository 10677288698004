import React from 'react'
import Right from '../header/Right'
import Navbar from '../header/Navbar';
import login from'../../images/login_in.svg'
// import fb from'../../images/fb.png'
// import google from'../../images/google.png'
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import  { useState } from 'react';
import { BASE_URL} from '../AllApi/CommonUrl';
const Signup = () => {
    const navigate = useNavigate()
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
});
const [errors, setErrors] = useState({});

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
      ...formData,
      [name]: value,
  });
};

const handleSubmit =async (e) => {
  e.preventDefault();
  const newErrors = validateForm(formData);
  setErrors(newErrors);

  if (Object.keys(newErrors).length === 0) {
    const characters = '1234567899';
    let result = '';
    for (let i = 0; i < 16; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
 try {
   const Url = `${BASE_URL}/loginorregister/customLoginRegister/`;
   const Idtoken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3Nzk5IiwidGltZVN0YW1wIjoiMjAyNC0wNS0xNyAwNTo1NTo0OSJ9.Dkt4VhHOJXINdDxwX1vPVmBQMhJFZ73FkJOBaVTtj78";
   const rawData ={
  "android_id":result,
 "gaid":result,
 "mobile_number":result,
 "login_type":"2",
 "email_id":formData.email,
 "password":formData.password,
 "user_name":formData.username,
 "is_tc_accepted":"Yes",
 "imsi":"132",
 "imei":"1212",
 "brand":"",
 "model":"",
 "operator":"",
 "connection_type":"",
 "apk_version":"33",
 "android_version":"33",
 "package_version":"",
 "fb_first_name":"",
 "fb_last_name":"",
 "fb_email_id":"",
 "fb_image_url":"",
 "fb_id":"",
 "gm_first_name":"",
 "gm_last_name":"",
 "gm_email_id":"",
 "gm_image_url":""
       }
   const response = await fetch(Url, {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       'Idtoken': `${Idtoken}`,
     },
     body: JSON.stringify(rawData),
   });
   if (!response.ok) {
     throw new Error('some error');
   }
   const jsonData = await response.json();
   console.log('rsult',jsonData.status)
   if(jsonData.status==1){ 
   navigate(`/`);
   }
   else{
    alert('User already exist')
    // setEmail('')
    // setPassword('');
  }
       }
       catch (error) {
           console.error('Error fetching data:', error);
           throw error;
         } 
      console.log('Form submitted successfully!');
  } else {
      console.log('Form submission failed due to validation errors.');
  }
};
const validateForm = (data) => {
  const errors = {};

  if (!data.username.trim()) {
      errors.username = 'Username is required';
  } else if (data.username.length < 4) {
      errors.username = 'Username must be at least 4 characters long';
  }
  else if(data.confirmPassword.indexOf(" ") !== -1){
    errors.username = 'Space not Allowed';
  }
  if (!data.email.trim()) {
      errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
  }
  else if(data.email.indexOf(" ") !== -1){
    errors.email = 'Space not Allowed';
  }

  if (!data.password) {
      errors.password = 'Password is required';
  } else if (data.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
  }
  else if(data.password.indexOf(" ") !== -1){
    errors.password = 'Space not Allowed';
  }

  if (data.confirmPassword !== data.password) {
      errors.confirmPassword = 'Password does not match';
  }
  if (data.confirmPassword.indexOf(" ") !== -1) {
    errors.confirmPassword = 'Space not Allowed';
    }

  return errors;
};
  return (
    <>
  <div className="section">
  <div className="s_left">
    <div className="header">
        <Navbar></Navbar>
      <div className="center center_data game_det">
        <img src={login} alt="login_in" />
        <h2 className="l_ttl">Create your account</h2>
        <form className="col s12" onSubmit={handleSubmit}>
          <div className="row">
            <div className="input-field col s12">
              <input
                placeholder="Name"
                id="first_name"
                type="text"
                className="validate"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
                {errors.username && (
                        <span className="error-message">
                            {errors.username}
                        </span>
                    )}
              {/* <label htmlFor="icon_prefix" className="">
                Name
              </label> */}
            </div>
            <div className="input-field col s12">
              <input
                placeholder="Email"
                id="first_name"
                type="email"
                className="validate"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
                {errors.email && (
                        <span className="error-message">
                            {errors.email}
                        </span>
                    )}
              {/* <label htmlFor="icon_prefix" className="">
                Email
              </label> */}
            </div>
            <div className="input-field col s12">
              <input
                placeholder="Password"
                id="password"
                type="password"
                className="validate"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
                  {errors.password && (
                        <span className="error-message">
                            {errors.password}
                        </span>
                    )}
              {/* <label htmlFor="icon_telephone" className="">
                Password
              </label> */}
            </div>
            <div className="input-field col s12">
              <input
                placeholder="Confirm Password"
                id="Confirm_Password"
                type="password"
                className="validate"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && (
                        <span className="error-message">
                            {errors.confirmPassword}
                        </span>
                    )}
              {/* <label htmlFor="icon_telephone" className="">
                Confirm Password
              </label> */}
            </div>
          </div>
          <button className="btn_white btn_gren btn_ht" type='submit'>Signup</button>
        </form>
        {/* <hr className="divider sign-in" /> */}
        {/* <div className="d_flex flex_2">
          <button type="submit" className="btn_blue2 btn_light btn_cont_with">
            <img src={google} />
            Google
          </button>
          <button type="submit" className="btn_blue2 btn_light btn_cont_with">
            <img src={fb} />
            Facebook
          </button>
        </div> */}
        <p className="mt_10 link_url">
          Already have an account?
          <NavLink to={'/Login'}>
          Sign in
          </NavLink>
        </p>
      </div>
    </div>
  </div>
  <Right></Right>
  </div>
    </>
  )
}

export default Signup
