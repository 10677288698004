import React from 'react'
import err from '../images/Error.svg'
import Right from './header/Right'
import CateHeader from './header/CateHeader'
import { NavLink } from 'react-router-dom'
const Error = () => {
  return (
    <>
        <div className="section">
            <div className="s_left">
                <CateHeader name={'Error'} path={'/'}></CateHeader>
                    <div class="center err">
                        <img src={err} class="top_img_bane err_imag" alt=""></img>
                        <p class="l_ttl">Page not Found!</p>
                        <NavLink to={'/'}>
                        <button class="btn_white btn_gren">Go to home</button>
                        </NavLink>
                      
                    </div>
                </div>
           
            <Right></Right>
        </div>
    </>
  )
}

export default Error
