import React from 'react'
import Right from '../header/Right'
import { gamelevel } from '../AllApi/GameLeveApi';
import { useState,useEffect } from 'react';
import {NavLink, useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { BASE_URL,AD_SHOW} from '../AllApi/CommonUrl';
import Navbar from '../header/Navbar';
import Cookies from 'js-cookie';
import QuizFooter from '../footer/QuizFooter';
import AdsComponents from'../Adsense/AdsComponents'
const GameLevel = () => {
  const url=window.location.href.split('/')[2]; 
  const user_id= typeof (Cookies.get('loginId'))==="undefined" ||Cookies.get('loginId') =="" ?Cookies.get('userId'):Cookies.get('loginId')
  const tok=typeof (Cookies.get('logintoken'))==="undefined"|| Cookies.get('logintoken')==""?Cookies.get('idtoken'):Cookies.get('logintoken')   
   let {constid} = useParams();
    const [level, setLevel] = useState([]);
    const [res, setRes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate()
    useEffect(() => {
        ; (async () => {
          if(!user_id){
            navigate("/");
          }
          try {
            const LevelEndPoint='goldgame/gameLevels'
            const result= await gamelevel(constid,user_id,BASE_URL,LevelEndPoint,tok);
            setRes(result)
            setLevel(result.alllavels)
            setIsLoading(false);
                } catch (error) {
            console.log(error) 
          }
        })()
      }, []);

    //   function onHandleClick(callback) {
    //     callback();
    // }
    function api(levelId) {
        // var link =`http://testing2.mchamplite.com/knifefling/?userId=${9}&contestId=${3}&levelNameId=${5}&newurl=http://localhost:3000/GamePlay`
        var link =`${res.game_url}?userId=${user_id}&contestId=${constid}&levelNameId=${levelId}&newurl=https://${url}/GamePlay`   
        window.location.href = link;  
    }
    //   const onHandleClick = () => {   
    //     navigate("/GamePlay"); 
    //  } 
    return (
        <>

            <div className="section">
                <div className="s_left">
                    <Navbar></Navbar>
                    {isLoading && <div className='center ldr_center'>
    <div className="preloader-wrapper big active">
      <div className="spinner-layer spinner-blue">
        <div className="circle-clipper left">
          <div className="circle"></div>
        </div><div className="gap-patch">
          <div className="className"></div>
        </div><div className="circle-clipper right">
          <div className="circle"></div>
        </div>
      </div>
    </div>
  </div>
                    }
                        {/* <div class="center">
                          <img src={res.image_banner} class="top_img_bane"/>
                        </div>
                         */}
   <div className="g_ads">
<p className="sponsored">Advertisement</p>

{AD_SHOW===1&&
  <AdsComponents adClient={"ca-pub-2489070569479477"} adSlot={9341299515} adFormat={"auto"} responsive={"true"}></AdsComponents>      }
      </div>

                        <div className="game_det">
                            <div className="flexx">
                                <p className="l_ttl">Select Level</p>
                                {/* <button className="htp">How To Play</button> */}
                            </div>
                            <div className="">
                                {level!=null &&
                              level.map((key,index) => {
                                    return(
                                <div className="game">
                                    <div className="game_img level_img">
                                        <img src={key.lavel_icon} alt="level1" />
                                    </div>
                                    <div className="game_info">
                                        <p>Level {key.lavel_name}</p>
                                        <p>Score: {key.score}</p>
                                        {/* <a  href={`${res.game_url}/${1}`}> */}
                                        {
                                            key.alpha==1 &&
                                            <button className={`btn_white btn_gren disable`}>{key.levelbuttontext}</button>

                                        }
                                        { key.alpha==0 &&
                                    <button className={`btn_white btn_gren`} onClick={() => api(key.lavel_name)} >{key.levelbuttontext}</button>
                                        }
                      
                                        {/* </a> */}
                                    </div>
                                </div>
                                 )
                                })
                            }
                            {res.levelCompleteStatus==1 &&
                                 <NavLink to={`/GameDetails/${constid}`}>
                                         <button className={`btn_white btn_gren`} >LeaderBoard</button>
                                         </NavLink>
                                          
                                         }
                            </div>
                        </div>
                        <QuizFooter></QuizFooter>
                        </div>
                    <Right></Right>
            </div>
        </>
    )
}

export default GameLevel
