import QuizInfo from "./components/routes/QuizInfo";
import TournamentRoute from "./components/routes/TournamentRoute";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import QuestionScreenRoute from "./components/routes/QuestionScreenRoute";
import ProgressRoute from "./components/routes/ProgressRoute";
import GameRoute from "./components/routes/GameRoute";
import GameDetails from "./components/Game/GameDetails";
import GameLevel from "./components/Game/GameLevel";
import GamePlay from "./components/Game/GamePlay";
import History from "./components/Progress/RedeemHist";
import Login from "./components/User/Login";
import SignUp from "./components/User/Signup";

import Error from "./components/Error";
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <GameRoute></GameRoute>
    ),
  },
  {
    path: "/QuizInfo/:id",
    element: (
   <QuizInfo></QuizInfo>
    ),
  },
{
  path: "/Screen",
  element: (
 <QuestionScreenRoute></QuestionScreenRoute>
  ),
},
{
  path: "/progress",
  element: (
    <ProgressRoute></ProgressRoute>
  ),
},
{
  path: "/Play/:id",
  element: (
<QuestionScreenRoute></QuestionScreenRoute>
  ),
},
{
  path: "/Game/:id",
  element: (
 <GameRoute></GameRoute>
  ),
},
{
  path: "/GameDetails/:contest",
  element: (
   <GameDetails></GameDetails>
  ),
},
{
  path: "/GameLevel/:constid",
  element: (
 <GameLevel></GameLevel>
  ),
},
{
  path: "/GamePlay/:user_id/:contest_id/:level_id/:score",
  element: (
<GamePlay></GamePlay>
  ),
},
{
  path: "/history",
  element: (
<History></History>
  ),
},
{
  path: "*",
  element: (
<Error></Error>
  ),
},
{
  path: "/Login",
  element: (
<Login></Login>
  ),
},
{
  path: "/SignUp",
  element: (
<SignUp></SignUp>
  ),
},
]);
function App() {
  return (
   <>
    <RouterProvider router={router} />
   
   </>
  );
}

export default App;
