import React from 'react'
import  { useState } from 'react';
import Right from '../header/Right'
import Navbar from '../header/Navbar';
import login from'../../images/login_in.svg'
import fb from'../../images/fb.png'
import google from'../../images/google.png'
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { BASE_URL} from '../AllApi/CommonUrl';
import Cookies from 'js-cookie';
const Login = () => {
  //set user
  // const user_id= typeof (Cookies.get('loginId'))==="undefined"?Cookies.get('userId'):Cookies.get('loginId')
  // const tok=typeof (Cookies.get('logintoken'))==="undefined"?Cookies.get('idtoken'):Cookies.get('logintoken')
  const navigate = useNavigate()
  //   const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
});


const [errors, setErrors] = useState({});

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
      ...formData,
      [name]: value,
  });
};

const handleSubmit =async (e) => {
  e.preventDefault();
  const newErrors = validateForm(formData);
  setErrors(newErrors);
  if (Object.keys(newErrors).length === 0) {
    const characters = '1234567899';
    let result = '';
    for (let i = 0; i < 16; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
 try {
   const Url = `${BASE_URL}/loginorregister/customLoginRegister/`;
   const Idtoken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3Nzk5IiwidGltZVN0YW1wIjoiMjAyNC0wNS0xNyAwNTo1NTo0OSJ9.Dkt4VhHOJXINdDxwX1vPVmBQMhJFZ73FkJOBaVTtj78";
   const rawData ={
  "android_id":result,
 "gaid":result,
 "mobile_number":result,
 "login_type":"1",
 "email_id":formData.email,
 "password":formData.password,
 "user_name":"",
 "is_tc_accepted":"Yes",
 "imsi":"132",
 "imei":"1212",
 "brand":"",
 "model":"",
 "operator":"",
 "connection_type":"",
 "apk_version":"33",
 "android_version":"33",
 "package_version":"",
 "fb_first_name":"",
 "fb_last_name":"",
 "fb_email_id":"",
 "fb_image_url":"",
 "fb_id":"",
 "gm_first_name":"",
 "gm_last_name":"",
 "gm_email_id":"",
 "gm_image_url":""
       }
   const response = await fetch(Url, {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       'Idtoken': `${Idtoken}`,
     },
     body: JSON.stringify(rawData),
   });
   if (!response.ok) {
     throw new Error('some error');
   }
   const jsonData = await response.json();
   console.log('rsult',jsonData)
   if(jsonData.status==1){
   Cookies.set('loginId', jsonData.user_id);
   Cookies.set('is_login_check', true);  
   Cookies.set('logintoken',jsonData.idtoken); 
   console.log('uid',jsonData.user_id)
   Cookies.set('username', jsonData.user_name);  
   console.log('tokennn',jsonData.idtoken)
   navigate(`/`);
   }
   else{
    alert('Invalid Password')
    // setEmail('')
    // setPassword('');
  }

       }
       catch (error) {
           console.error('Error fetching data:', error);
           throw error;
         } 
      console.log('Form submitted successfully!');
  } else {
      console.log('Form submission failed due to validation errors.');
  }
};


const validateForm = (data) => {
  const errors = {};

  if (!data.email.trim()) {
      errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
  }
  if (!data.password) {
      errors.password = 'Password is required';
  } else if (data.password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
  }
  return errors;
};



  return (
    <>
    <div className="section">
  <div className="s_left">
    <div className="header">
     <Navbar></Navbar>
      {/* login*/}
      <div className="center center_data game_det">
        <img src={login} alt="login_in" />
        <h2 className="l_ttl">Let's you in</h2>
        <form className="col s12" onSubmit={handleSubmit}>
          <div className="row">
            <div className="input-field col s12">
              <input
               type="email"
               className="validate"
               name="email"
               value={formData.email}
               onChange={handleChange}
                placeholder="Email"
              />
              {errors.email && (
                        <span className="error-message">
                            {errors.email}
                        </span>
                    )}
              {/* <label htmlFor="icon_prefix" className="">
                Email
              </label> */}
            </div>
            <div className="input-field col s12">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="validate"
                placeholder="Password"
              />
               {errors.password && (
                        <span className="error-message">
                            {errors.password}
                        </span>
                    )}
              {/* <label htmlFor="icon_telephone" className="">
                Password
              </label> */}
            </div>
          </div>
          <button className="btn_white btn_gren btn_ht" type="submit">Login</button>
        </form>
        {/* <p className="mt_10 link_url">
          <a href="#">Forgot your password?</a>
        </p> */}
        {/* <hr className="divider sign-in"/> */}
        {/* <div className="d_flex flex_2">
          <button type="submit" className="btn_blue2 btn_light btn_cont_with">
            <img src={google} />
            Google
          </button>
          <button type="submit" className="btn_blue2 btn_light btn_cont_with">
            <img src={fb} />
            Facebook 
          </button>
        </div> */}
        <p className="mt_10 link_url">
          Do not have an account?
          <NavLink to={'/Signup'}>
          Sign up
          </NavLink>
        </p>
      </div>
    </div>
  </div>
  <Right></Right>
</div>

    </>
  )
}

export default Login
