
// const BASE_URL = 'http://fantasyapi.mchamp.com/';
export const AddDiamond = async (id,level_id,BASE_URL,endpoint,tok) => {
    console.log(tok)
    console.log(id)
    try {
      const Url = `${BASE_URL}/${endpoint}`;
    const Idtoken =`${tok}`;
    const rawData ={
        "user_id":id,
        "reedem_date": "2024-07-30",
        "ip": "123.34.34",
        "level_complete":level_id
    }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Idtoken': `${Idtoken}`,
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json();  
    return jsonData;
        } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };
  