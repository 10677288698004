import React from 'react'
import { useNavigate } from "react-router-dom";
const GameHead = (props) => {
    const navigate = useNavigate()
    const res=(constest)=>{
          navigate(`/GameDetails/${constest}`);
        }
  return (
    <>
              {props.arr!=null &&
                  props.arr.map((data, index) => { 
                    return(
                <div className="game" onClick={() => res(data.contest_id)}>
                    <div className="game_img">
                        <img  src={data.icon_image_white} alt="kbc"/>
                      
                        <div className="played bottom-right">
                            <p>user Played: {data.total_user_playeds}</p>    
                        </div>
                    </div>
                    <div className="game_info">
                        <p>{data.contest_name}</p>
                       
                        <div className="entr entr2">
                            <div className="entry_value">
                                <p>{data.contest_cost_text}</p>
                                <p className="etry_am"><img src={data.entry_icon} alt="diamond"/>{data.contest_cost}</p>
                            </div>
                            <div className="entry_value">
                                <p>Max. Prize Pool</p>
                                <p className="etry_am daimond_redm"><img src={data.prize_icon}alt="diamond"/>{data.max_prize_pool}</p>
                            </div>
                        </div>
                        <div className="d_flex">
                            {/* <p>{data.sub_topic_name}</p> */}
                            <p>{data.contest_played_date}</p>
                        </div>
			        {data.joinbuttontxt=="Completed" &&<button className="btn_white btn_gren " onClick={() => res(data.contest_id)} >{data.joinbuttontxt}</button>

                        }
                         {data.joinbuttontxt=="Play Now" &&<button className="btn_white btn_gren" onClick={() => res(data.contest_id)}>{data.joinbuttontxt}</button>

                          }
                    </div>
                </div>
                )
            })
          }
    </>
  )
}

export default GameHead
