import Cookies from 'js-cookie';
// const BASE_URL = 'http://fantasyapi.mchamp.com/';
const COOKIE_NAME = 'userId';
const  COOKIE_TOKE= 'idtoken';
//genrate random user id
const generateRandomId = (length = 16) => {
    const characters = '1234567899';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }
    return result;
};
// store new user in db 
const ApiCall = async(uid) => {
  try {
    const Url = "https://mteapi.gamegirilite.com/loginorregister";
    const Idtoken ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMTY3Nzk5IiwidGltZVN0YW1wIjoiMjAyNC0wNS0xNyAwNTo1NTo0OSJ9.Dkt4VhHOJXINdDxwX1vPVmBQMhJFZ73FkJOBaVTtj78";
    const rawData ={
        "android_id":uid,
        "gaid":uid,
        "mobile_number":uid,
        "login_type":"3",
        "email_id":"",
        "is_tc_accepted":"Yes",
        "imsi":"132",
        "imei":"1212",
        "brand":"",
        "model":"",
        "operator":"",
        "connection_type":"",
        "apk_version":"33",
        "android_version":"33",
        "package_version":"",
        "fb_first_name":"",
        "fb_last_name":"",
        "fb_email_id":"",
        "fb_image_url":"",
        "fb_id":"",
        "gm_first_name":"",
        "gm_last_name":"",
        "gm_email_id":"",
        "gm_image_url":""
        }
    const response = await fetch(Url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Idtoken}`,
      },
      body: JSON.stringify(rawData),
    });
    if (!response.ok) {
      throw new Error('some error');
    }
    const jsonData = await response.json(); 
    Cookies.set(COOKIE_NAME, jsonData.user_id); 
    Cookies.set(COOKIE_TOKE, jsonData.idtoken); 
    console.log('data',jsonData.user_id);
    console.log('tokdd',jsonData.idtoken);
        }
        catch (error) {
            console.error('Error fetching data:', error);
            throw error;
          }

};
// Genrate new user 
export const checkAndHandleUserId  =() => {
    var CookeId = Cookies.get(COOKIE_NAME);
    if (!CookeId) {
        const userId = generateRandomId(); 
        ApiCall(userId)
    } else {
        // console.log('Existing user ID:', CookeId);
    }
    return {
        useId :Cookies.get(COOKIE_NAME),
        BASE_URL : 'https://mteapi.gamegirilite.com',
        tok :Cookies.get(COOKIE_TOKE) 
    };
  };
let x=checkAndHandleUserId();
    export const BASE_URL=x.BASE_URL
    export const user_id=x.useId
    export const tok=x.tok
    export const AD_SHOW=1//1 == on and 2 == off 


