import React from 'react'

const Right = () => {
  return (
    <div className="s_right">
     <h2>Welcome to Game <br></br>Tournament</h2>
  </div>
  
  )
}

export default Right
