import React from 'react'
import CateHeader from '../header/CateHeader'
import WalletInfo from '../Progress/WalletInfo'
import Right from '../header/Right'
import coin from '../../images/coin.png'
import coin1 from '../../images/coins_bg.png'
import diamond1 from '../../images/diamond_bg.png'
import credit from '../../images/credit.png'
import debit from '../../images/debit.png'
import diamond from '../../images/diamond.png'    
import { walletData } from '../AllApi/WalletApi';
import { BASE_URL} from '../AllApi/CommonUrl';
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { NavLink } from 'react-router-dom'
import { History } from '../AllApi/History';
const ProgressRoute = () => {
    const[coins,setCoins]=useState([]);
    const[point,setPoint]=useState('');
    const[diamonds,setDiamonds]=useState('');
    const [isLoading, setIsLoading] = useState(true);
    const user_id= typeof (Cookies.get('loginId'))==="undefined" ||Cookies.get('loginId') =="" ?Cookies.get('userId'):Cookies.get('loginId')
    const tok=typeof (Cookies.get('logintoken'))==="undefined"|| Cookies.get('logintoken')==""?Cookies.get('idtoken'):Cookies.get('logintoken')
    const[hist,sethist]=useState([]);
    useEffect(() => {
          ; (async () => {
            try {
                 const WalletEndPoint='userheaderdetail/homecategory'
              const result = await walletData(user_id,BASE_URL,WalletEndPoint,tok); // Replace with your endpoint
              const EndPoint='offer/redeemUserDiamondHistory'
              const resp = await History(user_id,BASE_URL,EndPoint,tok);
              sethist(resp.result)
              setCoins(result.data.wallet.coin_balance)
              setPoint(result.data.wallet.point_balance)
              setDiamonds(result.data.wallet.diamond_balance)
              setIsLoading(false)
              console.log(`Page rendered in ${performance.now().toFixed(2)} ms.`);
                  } catch (error) {
              console.log(error)
            }
          })()
        }, []);
    return (
        <>
            <div className="section">
                <div className="s_left">
                    <CateHeader name={'Wallet'} path={'/'}></CateHeader>
                    <div className="">
                        {/*coins Info section*/}
                        {/* <div className="c_info_tabs">
                            <CoinInfo coin={coin} value={coins}></CoinInfo>
                            <CoinInfo coin={ept} value={point}></CoinInfo>
                            <CoinInfo coin={diamond}  value={diamonds}></CoinInfo>
                        </div> */}
{isLoading && <div className='center ldr_center'>
            <div className="preloader-wrapper big active">
              <div className="spinner-layer spinner-blue">
                <div className="circle-clipper left">
                  <div className="circle"></div>
                </div><div className="gap-patch">
                  <div className="className"></div>
                </div><div className="circle-clipper right">
                  <div className="circle"></div>
                </div>
              </div>
            </div>
          </div>
          }
                      {isLoading===false && 
         <WalletInfo name={'Total Coins'} value={coins} image={coin} limage={coin1} status={1} user_id={user_id} tok={tok}></WalletInfo>     
          }
           {isLoading===false && 
              <WalletInfo name={'Total Diamonds'}  value={diamonds} image={diamond}  limage={diamond1} status={2} user_id={user_id} tok={tok}></WalletInfo>}
                
                <div class="flexx pdng_10">
                  <p class="l_ttl">Transection History</p>
                  <NavLink  to={"/history"}>
                  <button class="htp">See All</button>
                  </NavLink>
                </div>

                {/* <div class="ldr_list">
                    <div class="ldr_itm">
                      <div className='ldr_sb_itm'>
                        <img src={credit} alt='credit' />
                        <div>
                          <p className='ldr_sb_itm'>Buy Coins</p>
                          <p className='tming'>17/04/2024, 17: 14 pm</p>
                        </div>
                      </div>
                      <div class="ldr_sb_itm entry_value">
                        <p class="etry_am">
                          <img src="https://storage.googleapis.com/mchamp2/images/623add4046e93.png" alt="diamond"></img>
                          500
                        </p>
                      </div>
                    </div>
                </div> */}
   {
        isLoading===false && hist.length==0 && <h6 style={{color:"red",textAlign:"center"}}>No history </h6>
      }
               {  isLoading===false && hist!=null&& 
  hist.map((key,index) => {
    return(
      <div class="ldr_list">
      <div class="ldr_itm">
        <div className='ldr_sb_itm'>
        {key.msg!=null && key.credit_status==1&&
            <img src={credit} alt='credit' />
            }
             {key.msg!=null && key.credit_status==2&&
            <img src={debit} alt='credit' />
            }
         
          <div>                             
          {key.msg!=null &&  key.credit_status==1&&
            <p className='ldr_sb_itm'>Earn Coin </p>
            }
            {key.msg!=null &&  key.credit_status==2&&
            <p className='ldr_sb_itm'>Deduct Coin </p>
            }
            <p className='tming'>{key.create_date}</p>
          </div>
        </div>
        <div class="ldr_sb_itm entry_value">
          <p class="etry_am">
            <img src={coin}alt="diamond"></img>
            {key.diamond_quantity}
          </p>
        </div>
        
      </div>
  </div>
    )
  })
} 
                {/* <div className='btm_btn_fx'>
                  <button class="btn_white btn_gren ">Transaction History</button>
                </div> */}

                </div>                
                
               </div>
                <Right></Right>
            </div>
        </>
    )
}

export default ProgressRoute  
